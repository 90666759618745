import React, { useState, useEffect } from 'react';
import UserAccordion from './components/UserAccordion';
import './App.css';
import data from './asset/celebrities.json';

import SearchBar from './components/SearchBar';

const App = () => {
  const [users, setUsers] = useState([]);
  const [activeUserId, setActiveUserId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState([]);

  // To update the user list with calculated ages 
  useEffect(() => {
    const updatedUsers = data.map(user => ({
      ...user,
      age: calculateAge(user.dob)
    }));

    setUsers(updatedUsers);
    setSearchData(updatedUsers); // Initialize searchData with all users
  }, []);

  // Function to calculate age based on date of birth
  const calculateAge = (dob) => {
    const diff = new Date() - new Date(dob);
    return Math.floor(diff / 31557600000); 
  };

  // Function to handle the searchbar
  const onSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const updatedUsers = users.filter(user =>
      user.first.toLowerCase().includes(term.toLowerCase()) ||
      user.last.toLowerCase().includes(term.toLowerCase())
    );
    setSearchData(updatedUsers);
  };

  // Function to toggle the accordion for a user
  const toggleAccordion = (id) => {
    if (editMode) return; 
    setActiveUserId(activeUserId === id ? null : id); 
  };

  // Function to handle the toggle of edit mode for a specific user
  const handleEditToggle = (id) => {
    setEditMode(!editMode);
    setActiveUserId(id);
  };

  // Function to handle saving the updated user details
  const handleSave = (updatedUser) => {
    const updatedUsers = users.map(user => user.id === updatedUser.id ? updatedUser : user);
    setUsers(updatedUsers);
    setSearchData(updatedUsers); 
    setEditMode(false);
  };

  // Function to handle deleting a user
  const handleDelete = (id) => {
    const updatedUsers = users.filter(user => user.id !== id);
    setUsers(updatedUsers);
    setSearchData(updatedUsers); 
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        
        <div className='text-4xl font-bold text-pretty text-center items-center border rounded-lg shadow-md p-4 mb-4 bg-white'>
          Celebrity Manager
        </div>  
        
        <SearchBar searchTerm={searchTerm} onSearch={onSearch} />
        {searchData.length > 0 ? (
          <div className="user-list">
            
            {searchData.map(user => (
              <UserAccordion
                key={user.id}
                user={user}
                isActive={user.id === activeUserId}
                toggleAccordion={toggleAccordion}
                editMode={editMode}
                handleEditToggle={handleEditToggle}
                handleSave={handleSave}
                handleDelete={handleDelete}
              />
            ))}
          </div>
        ) : (
          // Message to display if no users are found
          <div className='flex mt-10 text-2xl font-semibold justify-center'>
            Nothing Found.
          </div>
        )}
      </div> 
      
    </div>
  );
};

export default App;
